import axios from "./index";

export default {
  getAll() {
    return axios.get("/v1/user");
  },
  create(data) {
    return axios.post("/v1/user", data);
  },
  update(uuid, data) {
    return axios.patch(`/v1/user/${uuid}`, data);
  },
  updateStatus(uuid) {
    return axios.patch(`/v1/user/status/${uuid}`);
  },
  resetPassword(uuid, data) {
    return axios.patch(`/v1/user/reset-password/${uuid}`, data);
  },
};
