<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers, minLength, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "@/app.config";
import userSvc from "@/services/user";
import { toast } from "vue3-toastify";

/**
 * Customers component
 */
export default {
  page: {
    title: "User",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "User",
      items: [
        {
          text: "User",
          active: true,
        },
      ],
      columns: [
        {
          field: "uuid",
          hidden: true,
        },
        {
          label: "Username",
          field: "username",
        },
        {
          label: "Role",
          field: "role",
        },
        {
          label: "Status",
          field: "isActive",
        },
        {
          label: "Created Date",
          field: "createdAt",
        },
        {
          label: "Updated Date",
          field: "updatedAt",
        },
        {
          label: "Deleted Date",
          field: "deletedAt",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      currentUsername:
        localStorage.getItem("userInfo") !== null
          ? JSON.parse(localStorage.getItem("userInfo")).username
          : "",
      rows: [],
      showModalCreate: false,
      showModalEdit: false,
      showModalResetPassword: false,
      submitted: false,
      usernameList: [],
      field: {
        title: "",
        uuid: "",
        username: "",
        password: "",
        confirmPassword: "",
        role: "",
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      field: {
        username: {
          required: helpers.withMessage("username is required", required),
        },
        password: {
          required: helpers.withMessage("password is required", required),
          minLength: minLength(6),
        },
        confirmPassword: {
          required: helpers.withMessage(
            "confirmPassword is required",
            required
          ),
          sameAsPassword: sameAs(this.field.password),
        },
        role: {
          required: helpers.withMessage("role is required", required),
        },
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      userSvc.getAll().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.rows = resp.data.data;
        resp.data.data.forEach((user) => {
          this.usernameList.push(user.username);
        });
      });
    },
    create() {
      this.field.uuid = "";
      this.field.username = "";
      this.field.role = "";
      this.field.password = "";
      this.field.confirmPassword = "";
      this.submitted = false;
      this.showModalCreate = true;
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        if (this.usernameList.includes(this.field.username)) {
          toast.error("this user has already been used", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          return;
        }
        const data = {
          username: this.field.username,
          role: this.field.role,
          password: this.field.password,
          pwd_node: this.field.password,
        };

        userSvc
          .create(data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Created Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Created Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalCreate = false;
            this.submitted = false;
          });
      }
    },
    edit(data) {
      this.field.title = data.username;
      this.field.uuid = data.uuid;
      this.field.username = data.username;
      this.field.password = "password";
      this.field.confirmPassword = "password";
      this.field.role = data.role;
      this.submitted = false;
      this.showModalEdit = true;
    },
    handleEditSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        if (
          this.usernameList.includes(this.field.username) &&
          this.field.title !== this.field.username
        ) {
          toast.error("this user has already been used", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          return;
        }
        const data = {
          username: this.field.username,
          role: this.field.role,
        };
        userSvc
          .update(this.field.uuid, data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Edited Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Edited Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalEdit = false;
            this.submitted = false;
          });
      }
    },
    resetPassword(data) {
      this.field.title = data.username;
      this.field.uuid = data.uuid;
      this.field.username = data.username;
      this.field.password = "";
      this.field.confirmPassword = "";
      this.field.role = data.role;
      this.submitted = false;
      this.showModalResetPassword = true;
    },
    handleResetPassword() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const data = {
          password: this.field.password,
          pwdNote: this.field.password,
        };

        userSvc
          .resetPassword(this.field.uuid, data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Reset Password Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Reset Password Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalResetPassword = false;
            this.submitted = false;
          });
      }
    },
    editStatus(uuid) {
      userSvc
        .updateStatus(uuid)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Update Status Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Update Status Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <!-- MODAL CREATE -->
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-success btn-rounded mb-2 me-2"
                    @click="create"
                  >
                    <i class="mdi mdi-plus me-1"></i> New User
                  </button>
                  <b-modal
                    v-model="showModalCreate"
                    title="Add New User"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="username">Username</label>
                            <input
                              id="username"
                              v-model="field.username"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && v$.field.username.$error,
                              }"
                            />

                            <div
                              v-if="submitted && v$.field.username.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.field.username.required.$message"
                                >{{ v$.field.username.required.$message }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="password">Password</label>
                            <input
                              id="password"
                              v-model="field.password"
                              type="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && v$.field.password.$error,
                              }"
                            />

                            <div
                              v-for="(item, index) in v$.field.password.$errors"
                              :key="index"
                              class="invalid-feedback"
                            >
                              <span v-if="item.$message">{{
                                item.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="confirmPassword"
                              >Confirm Password</label
                            >
                            <input
                              id="confirmPassword"
                              v-model="field.confirmPassword"
                              type="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && v$.field.confirmPassword.$error,
                              }"
                            />

                            <div
                              v-for="(item, index) in v$.field.confirmPassword
                                .$errors"
                              :key="index"
                              class="invalid-feedback"
                            >
                              <span v-if="item.$message">{{
                                item.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="type">Role</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                v-model="field.role"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.role.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >Choose...</b-form-select-option
                                >
                                <b-form-select-option value="admin"
                                  >Admin</b-form-select-option
                                >
                                <b-form-select-option value="user"
                                  >Agent</b-form-select-option
                                >
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.role.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="v$.field.role.required.$message">{{
                                  v$.field.role.required.$message
                                }}</span>
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button
                          variant="light"
                          @click="showModalCreate = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="success" class="ms-1"
                          >Create User</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL CREATE -->

                <!-- MODAL EDIT -->
                <div class="text-sm-end">
                  <b-modal
                    v-model="showModalEdit"
                    :title="`Edit User : ${field.title}`"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleEditSubmit">
                      <div class="row">
                        <div class="col-12">
                          <label for="name">Username</label>
                          <input
                            id="uuid"
                            v-model="field.uuid"
                            v-show="false"
                          />
                          <input
                            id="name"
                            v-model="field.username"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted && v$.field.username.$error,
                            }"
                          />

                          <div
                            v-if="submitted && v$.field.username.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="v$.field.username.required.$message">{{
                              v$.field.username.required.$message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="type">Role</label>
                            <b-form inline>
                              <b-form-select
                                class="form-control"
                                v-model="field.role"
                                :class="{
                                  'is-invalid':
                                    submitted && v$.field.role.$error,
                                }"
                              >
                                <b-form-select-option value=""
                                  >Choose...</b-form-select-option
                                >
                                <b-form-select-option value="admin"
                                  >Admin</b-form-select-option
                                >
                                <b-form-select-option value="user"
                                  >Agent</b-form-select-option
                                >
                              </b-form-select>
                              <div
                                v-if="submitted && v$.field.role.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="v$.field.role.required.$message">{{
                                  v$.field.role.required.$message
                                }}</span>
                              </div>
                            </b-form>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModalEdit = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="warning" class="ms-1"
                          >Update User</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL EDIT -->

                <!-- MODAL RESET PASSWORD -->
                <div class="text-sm-end">
                  <b-modal
                    v-model="showModalResetPassword"
                    :title="`Reset Password : ${field.title}`"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleResetPassword">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="password">Password</label>
                            <input
                              id="uuid"
                              v-model="field.uuid"
                              v-show="false"
                            />
                            <input
                              id="password"
                              v-model="field.password"
                              type="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && v$.field.password.$error,
                              }"
                            />

                            <div
                              v-for="(item, index) in v$.field.password.$errors"
                              :key="index"
                              class="invalid-feedback"
                            >
                              <span v-if="item.$message">{{
                                item.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="confirmPassword"
                              >Confirm Password</label
                            >
                            <input
                              id="confirmPassword"
                              v-model="field.confirmPassword"
                              type="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && v$.field.confirmPassword.$error,
                              }"
                            />

                            <div
                              v-for="(item, index) in v$.field.confirmPassword
                                .$errors"
                              :key="index"
                              class="invalid-feedback"
                            >
                              <span v-if="item.$message">{{
                                item.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button
                          variant="light"
                          @click="showModalResetPassword = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="success" class="ms-1"
                          >Reset Password</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL RESET PASSWORD -->
              </div>
            </div>
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <div class="button-items">
                      <b-button
                        size="sm"
                        variant="success"
                        @click="edit(props.formattedRow)"
                      >
                        <i class="mdi mdi-pencil font-size-16 align-middle"></i>
                        Edit
                      </b-button>
                      <b-button
                        size="sm"
                        variant="warning"
                        @click="resetPassword(props.formattedRow)"
                      >
                        <i
                          class="mdi mdi-lock-reset font-size-16 align-middle"
                        ></i>
                        Reset Password
                      </b-button>
                    </div>
                  </span>
                  <span v-if="props.column.field == 'role'">
                    <b-badge
                      v-if="props.row.role === 'admin'"
                      variant="success"
                      >{{ props.row.role }}</b-badge
                    >
                    <b-badge
                      v-else-if="props.row.role === 'user'"
                      variant="secondary"
                    >
                      Agent
                    </b-badge>
                    <b-badge v-else variant="secondary">
                      {{ props.row.role }}
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field == 'isActive'">
                    <b-form-checkbox
                      :disabled="currentUsername === props.row.username"
                      v-model="props.formattedRow.isActive"
                      name="check-button"
                      switch
                      @change="editStatus(props.formattedRow.uuid)"
                    >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-success"
                        v-if="props.formattedRow.isActive"
                        >On</span
                      >
                      <span
                        class="badge rounded-pill font-size-12 badge-soft-warning"
                        v-else
                        >Off</span
                      >
                    </b-form-checkbox>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
